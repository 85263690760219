import React, { useState } from "react";
import "../styled.css";
// moment
import moment from "moment";
// alerta
import SweetAlert from "react-bootstrap-sweetalert";

export default function ItemSelect(props) {
  const { data, deleteScheduleFunc } = props;
  const [confirm, setConfirm] = useState(false);

  return (
    <>
      {confirm && (
        <SweetAlert
          warning
          showCancel
          confirmBtnText="Sim"
          cancelBtnText="Não"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          title="Deseja excluir essa atividade da sua agenda?"
          onCancel={() => setConfirm(false)}
          onConfirm={() => deleteScheduleFunc(data) > setConfirm(false)}
        />
      )}

      <div className="div-card-item">
        <i className="bx bx-check-circle icon-save-list" />
        <div className="div-data-list">
          <a>{data.sala}</a>
          <a>
            {data.atividade} - {moment(data.dataHoraInicio).utc().format("LT")}
          </a>
        </div>
        <i
          className="bx bx-trash icon-delete-list"
          // onClick={() => deleteScheduleFunc(data)}
          onClick={() => setConfirm(true)}
        />
      </div>
    </>
  );
}
