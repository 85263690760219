import writeXlsxFile from 'write-excel-file';
import { getAppointmentsByName } from '../../../services/appointments';
import { formatDate } from '../../../utils/functions';

export async function createXlsx({
  eventId,
  name,
  countData,
  setFinishExport,
}) {
  function formatDateTime(data) {
    const datePart = data.split('T');
    return `${datePart[1].substring(0, 5)}`;
  }

  try {
    const schema = [
      {
        column: 'Nome do Participante',
        type: String,
        value: (appointment) => appointment.participantName,
      },
      {
        column: 'Email',
        type: String,
        value: (appointment) => appointment.email,
      },
      {
        column: 'Sala',
        type: String,
        value: (appointment) => appointment.sala,
      },
      {
        column: 'Sessão',
        type: String,
        value: (appointment) => appointment.atividade,
      },
      {
        column: 'Horário',
        type: String,
        value: (appointment) => formatDateTime(appointment.dataHoraInicio),
      },
    ];

    const limit = 50;

    const numberOfRequisitions = Math.ceil(countData / limit);
    const allRequests = [];

    for (let i = 1; i <= numberOfRequisitions; i += 1) {
      const response = await getAppointmentsByName({
        eventId,
        offset: i,
        limit,
        name,
      });

      allRequests.push(response.data);
      setTimeout(() => {}, 500);
      const progressBar = document.querySelector('.export-progress');
      const percentageBar = document.querySelector('.export-percentage');

      progressBar.style.width = `${(i / numberOfRequisitions) * 100}%`;
      percentageBar.innerHTML = `${((i / numberOfRequisitions) * 100).toFixed(
        0
      )}%`;

      if (i === numberOfRequisitions) {
        setTimeout(() => {
          setFinishExport(true);
        }, 1000);
      }
    }

    const appointment = allRequests.flat();

    await writeXlsxFile(appointment, {
      schema,
      fileName: 'relatorio-agendamentos.xlsx',
    });
  } catch (error) {
    console.log(error);
  }
}
