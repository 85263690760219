import React from 'react';
import '../styles/style.css';

export default function SectionComponent({ setIsOpen, isOpen, sectionName }) {
  return (
    <div className="personalization-section" onClick={() => setIsOpen(!isOpen)}>
      <span>{sectionName}</span>
      <i
        className={isOpen ? 'mdi mdi-arrow-up' : 'mdi mdi-arrow-down'}
        style={{ marginTop: 2 }}
      />
    </div>
  );
}
