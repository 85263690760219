import React, { useState, useEffect } from "react";
import ItemTimeOption from "./itemTimeRoom";
import { getScheduleTimes } from "../../../services/diversaTurismo";
import "../styled.css";
// moment
import moment from "moment";

export default function ListTimeRoom({ roomSelected, user, setStep }) {
  const [loading, setLoading] = useState(true);
  const [list1, setList1] = useState([]);
  const [list2, setList2] = useState([]);

  useEffect(() => {
    async function fetchList() {
      const res = await getScheduleTimes(user.idEvent, roomSelected);
      if (res.total > 0) {
        var day1 = [];
        var day2 = [];

        res.data.forEach((element) => {
          const day = moment(element.dataHoraInicio).format("DD/MM");

          if (day === "01/11") {
            day1.push(element);
          } else if (day === "02/11") {
            day2.push(element);
          } else return;
        });

        setList1(day1);
        setList2(day2);
      }

      setLoading(false);
    }

    fetchList();
  }, [roomSelected, user]);

  if (loading) return <a className="a-loading" />;

  return (
    <div className="div-items-list">
      {list1.length > 0 || list2.length > 0 ? (
        <>
          {list1.length > 0 && (
            <>
              <h4>Dia 01/11</h4>
              {list1.map((e, i) => (
                <div key={i} style={{ width: "100%" }}>
                  <ItemTimeOption data={e} user={user} setStep={setStep} />
                </div>
              ))}
            </>
          )}

          {list2.length > 0 && (
            <>
              <h4 style={{ marginTop: 35 }}>Dia 02/11</h4>
              {list2.map((e, i) => (
                <div key={i} style={{ width: "100%" }}>
                  <ItemTimeOption data={e} user={user} setStep={setStep} />
                </div>
              ))}
            </>
          )}
        </>
      ) : (
        <a style={{ color: "gray" }}>Não há estoque para essa sala</a>
      )}
    </div>
  );
}
