import React from "react";
import "../styled.css";
// componentes
import ItemSupplierOption from "./itemSupplierOption";
// opções
import { suppliers } from "./listOptions";

export default function ListSupplierRoom(props) {
  const { setStep, setRoomSelected } = props;

  return (
    <>
      <div className="div-items-list">
        {suppliers.sort().map((e, i) => (
          <div style={{ width: "100%" }} key={i}>
            <ItemSupplierOption
              setRoomSelected={setRoomSelected}
              title={e}
              setStep={setStep}
            />
          </div>
        ))}
      </div>
    </>
  );
}
