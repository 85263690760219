import React, { useEffect, useState } from "react";
import CardSchedule from "./CardSchedule";
import CardScheduleBlank from "../../components/CardScheduleBlank";
import ModalActivities from "./ModalActivities";
import { getAppointmentsByLogin } from "../../services/appointments";
import { getStockActivities } from "../../services/stockActivities";
import { extractDate, getToday, getDiffMinutes } from "../../utils/functions";

import "../../styles/style.css";

const dates = ["2024-05-13", "2024-05-14"];

function ListAvailableDates(props) {
  const { eventId, participantId } = props;
  const [isOpenModalActivities, setIsOpenModalActivities] = useState(false);
  const [datesAppointed, setDatesAppointed] = useState([]);
  const [appointmentMade, setAppointmentMade] = useState([]);
  const [dateActivity, setDateActivity] = useState("");
  const [init, setInit] = useState(false);
  const [stockActivities, setStockActivities] = useState([]);

  function getByDate(date) {
    return stockActivities.filter(
      (item) => extractDate(item.dataHoraInicio) === date
    );
  }

  function getEnabledDate(date) {
    return [...datesAppointed].includes(date);
  }

  function listLoadingCards() {
    return dates.map((e, i) => (
      <div class={`waiting-for-card1`} key={e}>
        <CardScheduleBlank date={e} />
      </div>
    ));
  }

  const groupByColumn = (arr, column) => {
    return arr.reduce((grouped, item) => {
      const key = item[column];
      return {
        ...grouped,
        [key]: [...(grouped[key] || []), item],
      };
    }, {});
  };

  async function appointmentsFilter(date) {
    const res = await getAppointmentsByLogin(eventId, participantId, null);
    if (res.data) {
      const groupedData = groupByColumn(res.data, "dataHoraFim");
      setDatesAppointed(
        new Set(Object.entries(groupedData).map((e) => extractDate(e[0])))
      );
      setAppointmentMade(
        Object.entries(groupedData).filter((e) => extractDate(e[0]))
      );
    }
    setInit(true);
  }

  function getAppointmentMageByDate(date) {
    return appointmentMade.filter((e) => extractDate(e[0]) === date)[0];
  }

  function checkAllApointmentsByDate(date) {
    const filterByData = getByDate(date);
    const stockFull = filterByData.map(
      (e) => e.quantidadeUtilizada < e.quantidadeMax
    );
    return stockFull.filter((el) => el === true).length > 0;
  }

  function avalilableActivities(date) {
    const filterByData = getByDate(date);
    const checkAvalilable = filterByData.filter(
      (e) => getDiffMinutes(e.dataHoraInicio) >= 30
    );
    return checkAvalilable.length > 0;
  }

  function checkExpires(date) {
    const formattedDate = getToday();
    return new Date(formattedDate) > new Date(date);
  }

  function handleClick(date) {
    const enabled = getEnabledDate(date);
    const checkAll = checkAllApointmentsByDate(date);
    const expires = checkExpires(date);
    const checkAvalilableActivities = avalilableActivities(date);

    if (!enabled && checkAll && !expires && checkAvalilableActivities) {
      setDateActivity(date);
      setIsOpenModalActivities(!isOpenModalActivities);
    }
  }

  async function getStock() {
    const res = await getStockActivities(eventId);
    if (res.data) {
      setStockActivities(res.data);
    }
  }

  function handleClickModal(date) {
    setDateActivity(date);
    setIsOpenModalActivities(!isOpenModalActivities);
  }

  useEffect(() => {
    getStock();
    appointmentsFilter();
  }, []);

  useEffect(() => {
    if (!isOpenModalActivities) {
      getStock();
      appointmentsFilter();
    }
  }, [isOpenModalActivities]);

  return (
    <>
      {isOpenModalActivities ? (
        <ModalActivities
          isOpen={isOpenModalActivities}
          toggle={() => handleClickModal()}
          data={getByDate(dateActivity)}
          participantId={participantId}
          date={dateActivity}
          eventId={eventId}
        />
      ) : (
        <div className="rules-text">
          {init &&
            dates.map((item, i) => (
              <div onClick={() => handleClick(item)}>
                <CardSchedule
                  date={item}
                  key={i}
                  data={stockActivities}
                  enabled={!getEnabledDate(item)}
                  appointmentMade={getAppointmentMageByDate(item)}
                  checkAllApointments={checkAllApointmentsByDate(item)}
                  avalilableActivities={avalilableActivities(item)}
                  expires={checkExpires(item)}
                  activity={true}
                />
              </div>
            ))}
          {!init && <div>{listLoadingCards()}</div>}
        </div>
      )}
    </>
  );
}

export default ListAvailableDates;
