//styles
import "bootstrap/dist/css/bootstrap.css";
import "./assets/scss/theme.scss";
//rotas
import { BrowserRouter, Routes, Route } from "react-router-dom";
//pages
import Senac from "./pages/senac";
import Report from "./pages/senac/report";
import Presentation from "./pages/presentation";
import EncontroLideres from "./pages/encontrodelideres";
import EncontroLideresReport from "./pages/encontrodelideres/report";
import EncontroLideresAgendaFotos from "./pages/encontrodelideres/agendamentoFotos";
import EncontroLideresAgendaFotosReport from "./pages/encontrodelideres/agendamentoFotos/report";
import Ntt from "./pages/Ntt";
import DiversaTurismo from "./pages/diversaTurismo";
import DsWorld from "./pages/dsworld";
import ResidenteSache from "./pages/residentesache";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/cncsenac" element={<Senac />} />
          <Route path="/intterconnected" element={<Ntt />} />
          <Route path="/report/" element={<Report />} />
          <Route path="/presentation" element={<Presentation />} />
          <Route path="/encontrodelideres" element={<EncontroLideres />} />
          <Route
            path="/encontrodelideres/report"
            element={<EncontroLideresReport />}
          />
          <Route
            path="/encontrodelideres/agendafotos"
            element={<EncontroLideresAgendaFotos />}
          />
          <Route
            path="/encontrodelideres/agendafotos/report"
            element={<EncontroLideresAgendaFotosReport />}
          />
          <Route path="/diversa" element={<DiversaTurismo />} />
          <Route path="/dsworld2024" element={<DsWorld />} />
          <Route path="/residentesache" element={<ResidenteSache />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
