import React, { useState, useEffect } from "react";
import "../styled.css";
// componentes
import ItemSelect from "./itemSaved";
// service
import {
  getScheduleSaved,
  deleteSchedule,
} from "../../../services/diversaTurismo";
// alerta
import SweetAlert from "react-bootstrap-sweetalert";
// moment
import moment from "moment";

export default function ListSavedRooms(props) {
  const { user } = props;
  const [list, setList] = useState([]);
  const [list1, setList1] = useState([]);
  const [list2, setList2] = useState([]);
  // carregamento
  const [loading, setLoading] = useState(true);
  // alerta
  const [deleted, setDeleted] = useState(false);

  function getList() {
    setLoading(true);
    setList1([]);
    setList2([]);

    getScheduleSaved(user.idEvent, user.idPart).then((res) => {
      if (res.total > 0) {
        var day1 = [];
        var day2 = [];

        res.data.forEach((element) => {
          const day = moment(element.dataHoraInicio).format("DD/MM");

          if (day === "01/11") {
            day1.push(element);
          } else if (day === "02/11") {
            day2.push(element);
          } else return;
        });

        setList1(day1);
        setList2(day2);
      }

      setLoading(false);
    });
    // .catch((error) => console.log("erro", error));
  }

  function deleteScheduleFunc(schedule) {
    deleteSchedule(schedule.id).then((res) => {
      if (res.message === "DELETED") {
        getList();
        setDeleted(true);
      }
    });
    // .catch((error) => console.log("erro", error));
  }

  useEffect(() => {
    if (user) getList();
  }, [user]);

  return (
    <>
      {deleted && (
        <SweetAlert
          success
          title="Deletado!"
          onConfirm={() => setDeleted(false)}
        >
          Atividade deletada com sucesso
        </SweetAlert>
      )}

      <div className="div-items-list">
        {loading ? (
          <a className="a-loading" />
        ) : list1.length > 0 || list2.length > 0 ? (
          // list
          //   .sort(function (itemA, itemB) {
          //     if (itemA.dataHoraInicio < itemB.dataHoraInicio) return -1;
          //     if (itemA.dataHoraInicio > itemB.dataHoraInicio) return 1;
          //   })
          //   .map((e, i) => (
          //     <div key={i} style={{ width: "100%" }}>
          //       <ItemSelect data={e} deleteScheduleFunc={deleteScheduleFunc} />
          //     </div>
          //   ))
          <>
            {list1.length > 0 && (
              <>
                <h4>Dia 01/11</h4>
                {list1
                  .sort(function (itemA, itemB) {
                    if (itemA.dataHoraInicio < itemB.dataHoraInicio) return -1;
                    if (itemA.dataHoraInicio > itemB.dataHoraInicio) return 1;
                  })
                  .map((e, i) => (
                    <div key={i} style={{ width: "100%" }}>
                      <ItemSelect
                        data={e}
                        deleteScheduleFunc={deleteScheduleFunc}
                      />
                    </div>
                  ))}
              </>
            )}

            {list2.length > 0 && (
              <>
                <h4 style={{ marginTop: 35 }}>Dia 02/11</h4>
                {list2
                  .sort(function (itemA, itemB) {
                    if (itemA.dataHoraInicio < itemB.dataHoraInicio) return -1;
                    if (itemA.dataHoraInicio > itemB.dataHoraInicio) return 1;
                  })
                  .map((e, i) => (
                    <div key={i} style={{ width: "100%" }}>
                      <ItemSelect
                        data={e}
                        deleteScheduleFunc={deleteScheduleFunc}
                      />
                    </div>
                  ))}
              </>
            )}
          </>
        ) : (
          <a style={{ color: "gray" }}>Você não possui agendas salvas</a>
        )}
      </div>
    </>
  );
}
