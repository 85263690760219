import React from 'react';
import { FaCalendarAlt } from 'react-icons/fa';
import { formatDate } from '../utils/functions';
import '../styles/style.css';

function CardScheduleBlank(props) {
  return (
    <div>
      <div className="card-schedule-disabled">
        <FaCalendarAlt className="card-schedule-icon-disabled" />
        <p>{formatDate(props.date)}</p>
        <div className="card-schedule-button-disabled">
          <p>Escolha sua atividade</p>
        </div>
      </div>
    </div>
  );
}

export default CardScheduleBlank;
