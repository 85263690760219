import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Label,
} from 'reactstrap';
import Select from 'react-select';
import { getStockActivities } from '../../services/stockActivities';
import {
  createEditAppointment,
  getAppointmentsByLogin,
} from '../../services/appointments';
import SweetAlert from 'react-bootstrap-sweetalert';

import Card from './components/Card';
import { data } from './model';

import './styles.css';
import logo from '../../assets/image/encontro-lideres/logo.png';
import imgAgenda from '../../assets/image/encontro-lideres/img-agenda.png';
import { FaExclamationCircle } from 'react-icons/fa';

export default function EncontroLideres() {
  const [participantId, setParticipantId] = useState('');
  const [participantName, setParticipantName] = useState('');
  const [email, setEmail] = useState(null);
  const [eventId, setEventId] = useState('');
  const [eventName, setEventName] = useState('');
  const [idioma, setIdioma] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showModalConsult, setShowModalConsult] = useState(false);
  const [time, setTime] = useState('');
  const [room, setRoom] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [successEdit, setSuccessEdit] = useState(false);
  const [errorEdit, setErrorEdit] = useState(false);
  const [theme, setTheme] = useState(null);
  const [timeStart, setTimeStart] = useState('');
  const [stockActivities, setStockActivities] = useState([]);
  const [appointmentsMade, setAppointmentsMade] = useState([]);
  const [nomeAgendamento, setNomeAgendamento] = useState('lidera-1');

  const { t, i18n } = useTranslation();

  useEffect(() => {
    getParams();
  }, []);

  useEffect(() => {
    if (!showModal && eventId) {
      getStock(eventId);
    }
  }, [showModal]);

  const toggle = (time) => {
    setShowModal(!showModal);
    setTime(time);
    reset();
  };
  const toggleConsult = () => {
    setShowModalConsult(!showModalConsult);
  };
  const edit = (time) => {
    setTime(time);
    setShowModal(!showModal);
    setEditMode(true);
  };

  function getParams() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (isValidUUID(urlParams.get('paxId'))) {
      setParticipantId(urlParams.get('paxId'));
    }
    setParticipantName(urlParams.get('paxName'));
    setEmail(urlParams.get('email'));
    setEventId(urlParams.get('eventId'));
    setEventName(urlParams.get('eventName'));
    getStock(urlParams.get('eventId'));
    setIdiom(urlParams.get('idiom'));
  }
  function setIdiom(idiom) {
    const lang =
      idiom === 'pt-br' || idiom === 'ptBr' || idiom?.toLowerCase() === 'pt'
        ? 'ptBr'
        : idiom === 'sp' || idiom === 'es'
        ? 'sp'
        : 'en';
    setIdioma(lang);
    i18n.changeLanguage(lang);
  }

  async function getStock(eventId) {
    const res = await getStockActivities(eventId);
    if (res?.total > 0) {
      setStockActivities(
        res.data.filter(
          (e) =>
            e.quantidadeMax > e.quantidadeUtilizada && e.atividade !== 'FOTO'
        )
      );
    }
  }

  function isValidUUID(uuidStr) {
    const uuidRegex =
      /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    return uuidRegex.test(uuidStr);
  }

  const getLectureSubject = () => {
    let options = [];
    const appointments = [
      ...new Map(
        appointmentsMade.map((obj) => [JSON.stringify(obj), obj])
      ).values(),
    ].filter((e) => e.time === time);

    if (stockActivities.length > 0) {
      const activities = stockActivities?.filter(
        (e) =>
          e.dataHoraInicio.split('T')[1].substring(0, 5) === time &&
          e.atividade !== appointments[0]?.theme
      );

      activities.map((e) =>
        options.push({ value: e.atividade, label: e.atividade })
      );
    }
    return options.sort((a, b) =>
      a.label > b.label ? 1 : a.label < b.label ? -1 : 0
    );
  };

  async function getAppointments(time, eventId, participantId) {
    if (eventId && participantId && nomeAgendamento) {
      const res = await getAppointmentsByLogin(
        eventId,
        participantId,
        nomeAgendamento
      );
      if (res.total > 0) {
        const activities = res?.data?.filter(
          (e) => e.dataHoraInicio.split('T')[1].substring(0, 5) === time
        );
        if (activities.length > 0) {
          setAppointmentsMade((prev) => [
            ...prev,
            {
              time: time,
              theme: activities[0]?.atividade,
            },
          ]);
        }

        return activities;
      }
    }
    return [];
  }

  const submit = () => {
    create();
  };

  async function create() {
    const data = {
      eventId: eventId,
      eventName: eventName,
      participantId: participantId,
      participantName: participantName,
      atividade: theme,
      sala: room,
      ativo: true,
      dataHoraInicio: timeStart,
      dataHoraFim: timeStart,
      email: email,
      nomeAgendamento: nomeAgendamento,
      idioma: idioma,
    };
    setTheme(null);
    const res = await createEditAppointment(data);

    if (res.message === 'CREATED') {
      setSuccessEdit(true);
    }
    if (res.message === 'NO_STOCK_AVAILABLE') {
      setErrorEdit(true);
    }
    toggle();
    setTime('');
    setAppointmentsMade([]);
  }

  const reset = () => {
    setRoom(null);
    setTheme(null);
    setEditMode(false);
  };

  const changeTheme = (value, action, time) => {
    if (action === 'clear') {
      setRoom('');
    }

    if (action === 'select-option') {
      setTheme(value);
      const filterRoom = stockActivities.filter(
        (e) =>
          e.atividade === value &&
          e.dataHoraInicio.split('T')[1].substring(0, 5) === time
      );
      setRoom(filterRoom[0]?.sala);
      setTimeStart(filterRoom[0]?.dataHoraInicio);
    }
  };

  return (
    <main className="el-main-container">
      <header className="el-header">
        <img src={logo} alt="logo" />
      </header>
      {/* <div className="el-description"> */}
      {participantId ? (
        <div className="el-description">
          <Label className="el-description-title">
            {t('Breakout Rooms | Garanta sua vaga!')}
          </Label>
          {/* <p style={{ marginBottom: 0 }}> */}
          <p style={{ marginBottom: '10px' }}>
            {t(
              `O LIDERA busca trazer maior aprofundamento na nossa estratégia para as nossas lideranças. Por isso, além dos momentos de plenária, teremos três sessões de Breakout Rooms para mergulharmos na estratégia de 24 das nossas 15 VS/CH, trazendo clareza ao nosso direcionamento de foco e enfatizando a excelência na execução.`
            )}
          </p>
          <p style={{ marginBottom: '10px' }}>
            {t(
              `Cada sessão terá duração de 50 minutos, divididas em 25 minutos de apresentação e 25 minutos de perguntas.`
            )}
          </p>
          <p style={{ marginBottom: '10px' }}>
            {t(
              `Teremos dois modelos de apresentação, salas silenciosas com o uso de fones de ouvido e salas de transmissão que serão exibidas para todo o Grupo Boticário e terão tradução para inglês, espanhol e libras.`
            )}
          </p>
          <p className="el-p-uppercase">
            {t(
              `Após selecionar suas 3 sessões, o salvamento é automático, ao finalizar basta clicar em sair para completar a sua inscrição.`
            )}
          </p>
          {/* <p style={{ marginBottom: '2%' }}>
            {t(
              `Pensando nisso, que tal já começar a montar sua programação? Basta escolher até 3 temas de seu interesse, desde que as sessões aconteçam em horários distintos.`
            )}
          </p> */}
        </div>
      ) : (
        <div className="el-description">
          <div className="el-warning-msg">
            <FaExclamationCircle size={40} />
            <p>
              {t(
                'Olá! Não identificamos o seu cadastro. Por favor, entre em contato com o suporte para ter acesso ao agendamento de breakroom'
              )}
              .
            </p>
          </div>
        </div>
      )}
      {/* </div> */}
      <section className="el-section">
        {eventId &&
          participantId &&
          data.map((el) => (
            <Card
              imagePtEs={el.imagePtEs}
              imageEn={el.imageEng}
              eventId={eventId}
              participantId={participantId}
              time={el.time}
              toggle={toggle}
              appointments={getAppointments}
              status={showModal}
              edit={edit}
              t={t}
              idiom={i18n.language}
            />
          ))}
      </section>
      {participantId && (
        <div className="el-button-schedule">
          <Button color="primary" onClick={toggleConsult}>
            {t('Consultar Agenda')}
          </Button>
          <p>
            {t(
              `Para consultar a agenda completa das breakouts clique no botão acima.`
            )}
          </p>
        </div>
      )}

      {successEdit && (
        <SweetAlert
          success
          title={`${t('Parabéns')}!`}
          onConfirm={() => {
            setSuccessEdit(false);
          }}
        >
          {t('A sua participação neste Breakout Room está garantida')}.
        </SweetAlert>
      )}

      {errorEdit && (
        <SweetAlert
          warning
          title={t('Desculpe')}
          onConfirm={() => {
            setErrorEdit(false);
          }}
        >
          {t(`Agendamentos esgotados para essa sala`)}
        </SweetAlert>
      )}

      {showModal && (
        <>
          <Modal
            backdrop={false}
            centered={true}
            isOpen={showModal}
            toggle={() => toggle()}
          >
            <ModalHeader toggle={() => toggle()}>
              {t('Agendamento')}
            </ModalHeader>
            <ModalBody>
              <p style={{ margin: 0, fontWeight: '500' }}>
                {t('Escolha o tema que deseja acompanhar')}
              </p>
              <p
                style={{
                  fontStyle: 'italic',
                  fontSize: '11px',
                }}
              >
                {editMode &&
                  `${t(
                    'Para confirmar o novo agendamento, não esqueça de clicar em Salvar após fazer as alterações. Lembre-se, se você não salvá-lo, a programação original permanecerá inalterada.'
                  )}`}
              </p>
              <Row>
                <Col>
                  <label className="form-label">{t('Horário')}</label>
                  <input
                    className="form-control"
                    type="text"
                    value={time}
                    disabled
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <label className="form-label" style={{ marginTop: '10px' }}>
                    {t('Tema')}
                  </label>
                  <Select
                    options={getLectureSubject()}
                    isClearable={true}
                    onChange={(e, { action }) =>
                      changeTheme(e?.value, action, time)
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <label className="form-label" style={{ marginTop: '10px' }}>
                    {t('Sala')}
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    value={room}
                    disabled
                  />
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={submit} disabled={!theme}>
                {t('Salvar')}
              </Button>
              <Button color="secondary" onClick={toggle}>
                {t('Cancelar')}
              </Button>
            </ModalFooter>
          </Modal>
        </>
      )}

      {showModalConsult && (
        <div>
          <Modal
            backdrop={false}
            centered={true}
            isOpen={showModalConsult}
            size="xl"
            toggleConsult={() => toggleConsult()}
          >
            <ModalHeader
              style={{ marginTop: '10px', height: '1px' }}
              toggle={() => toggleConsult()}
            >
              {t('Agenda')}
            </ModalHeader>
            <ModalBody>
              <Row style={{ textAlign: 'center' }}>
                <Col style={{ display: 'flex' }}>
                  <img
                    src={imgAgenda}
                    alt="agenda"
                    width="100%"
                    height="100%"
                  />
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter style={{ textAlign: 'center' }}>
              {/* <Button color="secondary" onClick={toggleConsult}>
                {t('Fechar')}
              </Button> */}
            </ModalFooter>
          </Modal>
        </div>
      )}
    </main>
  );
}
