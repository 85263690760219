import React, { useState, useEffect } from "react";
import "./styled.css";
import { Card, CardTitle, CardBody, Row, Col, Button } from "reactstrap";
import { useSearchParams } from "react-router-dom";
// componentes
import ListAllRooms from "./listRooms";
import ListSavedRooms from "./listSaved";
import ListTimeRoom from "./listRoomTimes";
import ListSupplierRoom from "./listSupplier";

export default function DiversaTurismo() {
  const [step, setStep] = useState(1);
  const [user, setUser] = useState(null);
  const [searchParams] = useSearchParams();
  // selecionados
  const [roomSelected, setRoomSelected] = useState(null);
  // fornecedor
  const [isSupplier, setIsSupplier] = useState(false);

  useEffect(() => {
    if (window.location.search) {
      setUser({
        idEvent: searchParams.get("idEvent"),
        idPart: searchParams.get("idPart"),
      });
    }
  }, []);

  return (
    <section className="section-diversa">
      <Card className="card-diversa">
        <CardBody>
          <Row
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {/* botões de ação da listagem */}
            {step === 1 && (
              <Button
                className="btn btn-success"
                style={{
                  width: "auto",
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "#2ba1c0",
                }}
                onClick={() => setStep(2)}
              >
                Ver agenda
                <i
                  className="bx bx-list-ol"
                  style={{ fontSize: 19, marginLeft: 5 }}
                />
              </Button>
            )}

            {step === 2 && (
              <Button
                className="btn btn-success"
                style={{
                  width: "auto",
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "#2ba1c0",
                }}
                onClick={() => setStep(1)}
              >
                Ver meus agendamentos
                <i
                  className="bx bx-check-circle"
                  style={{ fontSize: 19, marginLeft: 5 }}
                />
              </Button>
            )}

            {(step === 3 || step === 4) && (
              <Button
                className="btn btn-success"
                style={{
                  width: "auto",
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "#2ba1c0",
                }}
                onClick={() => {
                  if (step === 3 && isSupplier) {
                    setStep(4);
                  } else setStep(2);
                }}
              >
                {step === 3 && isSupplier
                  ? "Voltar para Expositores Parceiros"
                  : "Voltar para agenda"}

                <i
                  className="bx bx-list-ol"
                  style={{ fontSize: 19, marginLeft: 5 }}
                />
              </Button>
            )}
          </Row>

          <CardTitle style={{ marginTop: 20 }} onClick={() => console.log(roomSelected)}>
            {/* mensagem dinamica para o titulo da página */}
            {step === 1 && "Meus Agendamentos"}
            {step === 2 && "Agenda"}
            {step === 3 && `Horários de ${roomSelected}`}
            {step === 4 && "Expositores Parceiros"}
          </CardTitle>

          {/* lista de items inscritos */}
          {step === 1 && <ListSavedRooms user={user} />}
          {/* lista de todos os items */}
          {step === 2 && (
            <ListAllRooms
              setStep={setStep}
              setRoomSelected={setRoomSelected}
              setIsSupplier={setIsSupplier}
              user={user}
            />
          )}
          {/* lista de items da sala */}
          {step === 3 && (
            <ListTimeRoom
              roomSelected={roomSelected}
              user={user}
              setStep={setStep}
            />
          )}
          {/* lista de Expositores Parceiros */}
          {step === 4 && (
            <ListSupplierRoom
              setStep={setStep}
              setRoomSelected={setRoomSelected}
            />
          )}
        </CardBody>
      </Card>
    </section>
  );
}
