import React, { useState, useEffect } from 'react';
import ListAvailableDatesPresentation from './ListAvailableDates';
// import { getGruposByLogin } from '../../services/login';

import '../../styles/style.css';

export default function Presentation() {
  const [participantId, setParticipantId] = useState('');
  const [eventId, setEventId] = useState('');
  const [groups, setGroups] = useState(null);

  useEffect(() => {
    getParams();
  }, []);

  function getParams() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    // getGroups(urlParams.get('paxId'));
    setParticipantId(urlParams.get('paxId'));
    setEventId(urlParams.get('eventId'));
  }

  // async function getGroups(login) {
  //   const res = await getGruposByLogin(login);
  //   if (res.data) {
  //     setGroups(res.data);
  //   }
  // }

  return (
    <main className="main-container">
      <section>
        {participantId && eventId && (
          <ListAvailableDatesPresentation
            participantId={participantId}
            eventId={eventId}
            // groups={groups}
          />
        )}
      </section>
    </main>
  );
}
