import React from 'react';

import '../styles/style.css';

function CardActivity(props) {
  const { name } = props;
  return (
    <div className="card-activity">
      <p>{name}</p>
    </div>
  );
}

export default CardActivity;
