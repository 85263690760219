import React, { useState, useEffect } from "react";
import "../styled.css";
// alerta
import SweetAlert from "react-bootstrap-sweetalert";
// moment
import moment from "moment";
// service
import { createSchedule } from "../../../services/diversaTurismo";

export default function ItemTimeOption(props) {
  const { data, user, setStep } = props;
  const [confirm, setConfirm] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  // validações para as salas específicas
  const [showName, setShowName] = useState(true);

  async function saveSchedule() {
    const dataSchedule = {
      eventId: user.idEvent,
      participantId: user.idPart,
      atividade: data.atividade,
      sala: data.sala,
      dataHoraInicio: data.dataHoraInicio,
      dataHoraFim: data.dataHoraFim,
    };

    createSchedule(dataSchedule)
      .then((res) => {
        if (res.message === "CREATED") {
          setConfirm(false);
          setSuccess(true);
        } else if (res.message === "APPOINTMENT_MADE") {
          setConfirm(false);
          setError("Você já se inscreveu nessa atividade");
        } else if (res.message === "APPOINTMENT_MADE_IN_OTHER_TIME") {
          setConfirm(false);
          setError("Você já está inscrito em uma atividade nesse horário");
        } else {
          setConfirm(false);
          setError(
            "Erro ao se cadastrar na atividade, tente novamente em breve."
          );
        }
      })
      .catch((error) => {
        // console.log("erro", error);
      });
  }

  useEffect(() => {
    if (
      data.atividade === "Espaço Diversa WOW Dubai" ||
      data.atividade === "Ativação: What do you know about ..."
    ) {
      setShowName(false);
    }
  }, []);

  if (data.quantidadeUtilizada < data.quantidadeMax)
    return (
      <>
        {confirm && (
          <SweetAlert
            warning
            title="Deseja confirmar este agendamento neste horário?"
            showCancel
            confirmBtnText="Sim"
            cancelBtnText="Não"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => saveSchedule()}
            onCancel={() => setConfirm(false)}
          />
        )}

        {success && (
          <SweetAlert
            success
            title="Horário confirmado com sucesso!"
            onConfirm={() => setStep(1) > setSuccess(false)}
          />
        )}

        {error && (
          <SweetAlert error title="Ooops..." onConfirm={() => setError("")}>
            {error}
          </SweetAlert>
        )}

        <div className="div-card-item" onClick={() => setConfirm(true)}>
          <div
            className="div-data-list"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <a>
              {showName && `${data.atividade} - `}
              {moment(data.dataHoraInicio).utc().format("LT")}
              {!showName && " - Duração de 20 min"}
            </a>
            <i
              className="bx bx-add-to-queue icon-save-list"
              style={{ backgroundColor: "white", color: "green" }}
            />
          </div>
        </div>
      </>
    );
}
