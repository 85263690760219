import axios from 'axios';
const urlBase =
  'https://gateway-admin-plus.sistemainteegra.com.br/api-admin-mobile';

async function getGruposByLogin(loginId) {
  const config = {
    method: 'get',
    url: `${urlBase}/temp/logingrupos/${loginId}/participant`,
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

export { getGruposByLogin };
