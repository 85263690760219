import React, { useState, useEffect } from "react";
import "../styled.css";
// componentes
import ItemTimeOption from "./itemTimeOption";
// service
import { getScheduleTimes } from "../../../services/diversaTurismo";

export default function ListTimeRoom(props) {
  const { roomSelected, user, setStep } = props;
  const [list, setList] = useState([]);
  // carregamento
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log({ list, roomSelected });
  }, [list, roomSelected]);

  function getList() {
    getScheduleTimes(user.idEvent, roomSelected).then((res) => {
      if (res.total > 0) {
        setList(res.data);
      }

      setLoading(false);
    });
    // .catch((error) => console.log("erro", error));
  }

  useEffect(() => {
    if (user) getList();
  }, [roomSelected]);
  
  return (
    <>
      <div className="div-items-list">
        {loading ? (
          <a className="a-loading" />
        ) : list.length > 0 ? (
          list.map((e, i) => (
            <div key={i} style={{ width: "100%" }}>
              <ItemTimeOption data={e} user={user} setStep={setStep} />
            </div>
          ))
        ) : (
          <a style={{ color: "gray" }}>Não há estoque para essa sala</a>
        )}
      </div>
    </>
  );
}
