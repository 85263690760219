import writeXlsxFile from "write-excel-file";
import { getAppointmentsByEvent } from "../../../services/appointments";
import { formatDate } from "../../../utils/functions";

export async function createXlsx({ eventId, countData }) {
  function formatDateTime(data) {
    const datePart = data.split("T");
    return `${formatDate(datePart[0])} ${datePart[1].substring(0, 5)}`;
  }

  try {
    const schema = [
      {
        column: "ID do Participante",
        type: String,
        value: (appointment) => appointment.participantId,
      },
      {
        column: "Nome do Participante",
        type: String,
        value: (appointment) => appointment.participantName,
      },
      {
        column: "Sala",
        type: String,
        value: (appointment) => appointment.sala,
      },
      {
        column: "Atividade",
        type: String,
        value: (appointment) => appointment.atividade,
      },
      {
        column: "Data e Hora do Início",
        type: String,
        value: (appointment) => formatDateTime(appointment.dataHoraInicio),
      },
      {
        column: "Data e Hora do Fim",
        type: String,
        value: (appointment) => formatDateTime(appointment.dataHoraFim),
      },
    ];

    const limit = 50;

    const numberOfRequisitions = Math.ceil(countData / limit);
    const allRequests = [];

    for (let i = 1; i <= numberOfRequisitions; i += 1) {
      const response = await getAppointmentsByEvent({
        eventId,
        offset: i,
        limit,
      });

      allRequests.push(response.data);
      setTimeout(() => {}, 500);
    }

    const appointment = allRequests.flat();

    await writeXlsxFile(appointment, {
      schema,
      fileName: "relatorio-agendamentos.xlsx",
    });
  } catch (error) {
    console.log(error);
  }
}
