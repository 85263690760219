import React, { useEffect, useState } from "react";
import { getScheduleTimes } from "../../../services/diversaTurismo";
import "../styled.css";

export default function ItemRoomOption({
  setStep,
  title,
  setRoomSelected,
  setIsSupplier,
  user,
}) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    async function fetchScheduleTimes() {
      const data = await getScheduleTimes(user.idEvent, title);
      setShow(data.total > 0);
    }

    fetchScheduleTimes();
  }, [title, user]);

  const handleClick = () => {
    setRoomSelected(title);
    setStep(3);
    setIsSupplier(false);
  };

  if (!show) return null;

  return (
    <div className="div-card-item" onClick={handleClick}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          padding: "4px",
        }}
      >
        <a>{`${title} - Ver horários`}</a>
        <i className="bx bxs-right-arrow-circle icon-save-list" />
      </div>
    </div>
  );
}
