import React, { useState, useEffect } from "react";
import "../styled.css";
// service
import { getScheduleTimes } from "../../../services/diversaTurismo";

export default function ItemRoomOption(props) {
  const { setStep, title, setRoomSelected, setIsSupplier, user } = props;
  const [show, setShow] = useState(false);

  function stepFunction() {
    if (title === "Circuito de Reuniões - One to One") {
      setStep(4);
      setIsSupplier(true);
    } else {
      setRoomSelected(title);
      setStep(3);
      setIsSupplier(false);
    }
  }

  async function verifyShowRoom() {
    if (title === "Circuito de Reuniões - One to One") {
      setShow(true);
      return;
    }

    var data = await getScheduleTimes(user.idEvent, title);

    if (data.total > 0) {
      setShow(true);
    } else setShow(false);
  }

  useEffect(() => {
    verifyShowRoom();
  }, []);

  if (show)
    return (
      <>
        <div className="div-card-item" onClick={() => stepFunction()}>
          <div
            className="div-data-list"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <a>{title} - Ver horários</a>
            <i
              className="bx bxs-right-arrow-circle icon-save-list"
              style={{ backgroundColor: "white", color: "green" }}
            />
          </div>
        </div>
      </>
    );
}
