import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing"; // Must import second
import './i18n/index';
// Sentry.init({
//   dsn: "https://800756f6b78041a4a021f3378f424efd@o1149424.ingest.sentry.io/4504129649049600",
//   integrations: [new BrowserTracing()],

//   tracesSampleRate: 1.0,
// });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
