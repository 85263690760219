import React, { useState, useEffect } from "react";
import "../styled.css";
// componentes
import ItemSelect from "./itemSaved";
// service
import {
  getScheduleSaved,
  deleteSchedule,
} from "../../../services/diversaTurismo";
// alerta
import SweetAlert from "react-bootstrap-sweetalert";

export default function ListSavedRooms(props) {
  const { user, setAllRoomsSaved } = props;
  const [list, setList] = useState([]);
  // carregamento
  const [loading, setLoading] = useState(true);
  // alerta
  const [deleted, setDeleted] = useState(false);

  function getList() {
    getScheduleSaved(user.idEvent, user.idPart).then((res) => {
      if (res.data) {
        setList(res.data);
        setAllRoomsSaved(res.data);
      }

      setLoading(false);
    });
    // .catch((error) => console.log("erro", error));
  }

  function deleteScheduleFunc(schedule) {
    deleteSchedule(schedule.id).then((res) => {
      if (res.message === "DELETED") {
        getList();
        setDeleted(true);
      }
    });
    // .catch((error) => console.log("erro", error));
  }

  useEffect(() => {
    if (user) getList();
  }, [user]);

  return (
    <>
      {deleted && (
        <SweetAlert
          success
          title="Deletado!"
          onConfirm={() => setDeleted(false)}
        >
          Atividade deletada com sucesso
        </SweetAlert>
      )}

      <div className="div-items-list">
        {loading ? (
          <a className="a-loading" />
        ) : list.length > 0 ? (
          list
            .sort(function (itemA, itemB) {
              if (itemA.dataHoraInicio < itemB.dataHoraInicio) return -1;
              if (itemA.dataHoraInicio > itemB.dataHoraInicio) return 1;
            })
            .map((e, i) => (
              <div key={i} style={{ width: "100%" }}>
                <ItemSelect data={e} deleteScheduleFunc={deleteScheduleFunc} />
              </div>
            ))
        ) : (
          <a style={{ color: "gray" }}>Você não possui agendas salvas</a>
        )}
      </div>
    </>
  );
}
