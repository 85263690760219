import axios from "axios";
const urlBase =
  "https://gateway-admin-plus.sistemasinteegra.com.br/api-admin-mobile";
//const urlBase = 'https://gateway-admin-plus.inteegrav2.com.br/api-admin-mobile';

async function createAppointment(data) {
  const config = {
    method: "post",
    url: `${urlBase}/temp/agendamentos`,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

async function createEditAppointment(data) {
  const config = {
    method: "put",
    url: `${urlBase}/temp/agendamentos`,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

async function getAppointmentsByLogin(eventId, loginId, nomeAgendamento = "") {
  const config = {
    method: "get",
    url: `${urlBase}/temp/agendamentos/${eventId}/event/${loginId}/participant/${nomeAgendamento}/agendamento/`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

async function getAppointmentsByEvent({
  eventId,
  offset,
  limit,
  search,
  columnSearch,
}) {
  const config = {
    method: "get",
    url: `${urlBase}/temp/agendamentosbyevent/${eventId}/event/?offset=${offset}&limit=${limit}&search=${search}&columnSearch=${columnSearch}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

async function getAppointmentsByName({
  eventId,
  offset,
  limit,
  search,
  columnSearch,
  name,
}) {
  const config = {
    method: "get",
    url: `${urlBase}/temp/agendamentosbyname/${eventId}/event/${name}/name?offset=${offset}&limit=${limit}&search=${search}&columnSearch=${columnSearch}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

async function deleteAppointments(eventId) {
  const config = {
    method: "delete",
    url: `${urlBase}/temp/agendamentos/${eventId}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

export {
  createAppointment,
  createEditAppointment,
  getAppointmentsByEvent,
  getAppointmentsByLogin,
  getAppointmentsByName,
  deleteAppointments,
};
