import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  Collapse,
  Input,
  Row,
  Table,
} from 'reactstrap';

import { createXlsx } from './createXlsx';
import Pagination from '../../../../components/pagination';
import WaitExport from '../../components/WaitExport';

import {
  getAppointmentsByEvent,
  getAppointmentsByName,
} from '../../../../services/appointments';
import { formatDate, formatTime } from '../../../../utils/functions';
import SearchFilters from '../../../../components/SearchFilters';
import SectionComponent from '../../../../components/SectionComponent';
import useDebounce from '../../../../hooks/useDebounce';

import '../../../../styles/reports.css';

export default function Report() {
  const [appointments, setAppointments] = useState([]);
  const [search, setSearch] = useState('');
  const [eventId, setEventId] = useState('');
  const [name, setName] = useState('lidera-2');

  //Pagination
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  const [finishExport, setFinishExport] = useState(false);
  const [exportInProgress, setExportInProgress] = useState(false);

  // collapse
  const [isOpen, setIsOpen] = useState(false);
  const [collapseIsOpen, setCollapseIsOpen] = useState(false);
  const [activeFilter, setActiveFilter] = useState('participantName');

  const columnsFilter = [
    { name: 'Participante', value: 'participantName', type: 'text' },
    { name: 'email', value: 'email', type: 'text' },
    { name: 'sala', value: 'sala', type: 'text' },
    // { name: 'Sessão', value: 'atividade', type: 'text' },
    { name: 'data', value: 'dataHoraInicio', type: 'date' },
    { name: 'hora', value: 'hora', type: 'text' },
  ];

  const debouncedSearch = useDebounce(search, 300);

  const reverseDate = () => {
    const reverse = debouncedSearch
      .split('/')
      .reverse()
      .join('/')
      .replace(/\//g, '-');
    return reverse;
  };

  function treatmentSearch() {
    if (
      columnsFilter.filter((item) => item.value === activeFilter)[0].type ===
      'date'
    ) {
      return reverseDate();
    }
    return debouncedSearch;
  }

  function setActiveFilterHandle(columnActive) {
    setActiveFilter(columnActive);
    setSearch('');
  }

  function getDate(data) {
    const datePart = data.split('T');
    return `${formatDate(datePart[0])}`;
  }

  function formatDateTime(data) {
    const datePart = data.split('T');
    return `${datePart[1].substring(0, 5)}`;
  }
  const appointmentsByEvent = async () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const response = await getAppointmentsByName({
      eventId: urlParams.get('eventId'),
      offset: currentPage,
      limit: itemsPerPage,
      search: treatmentSearch(),
      columnSearch: activeFilter,
      name: name,
    });
    setAppointments(response.data);
    setTotalItems(response.total);
    setEventId(urlParams.get('eventId'));
  };

  useEffect(() => {
    appointmentsByEvent();
  }, []);

  useEffect(() => {
    appointmentsByEvent();
  }, [debouncedSearch, currentPage, itemsPerPage]);
  return (
    <div className="report-container">
      <WaitExport
        isOpen={exportInProgress}
        name="Agendamentos"
        preposicao="dos"
        finishExport={finishExport}
        setFinishExport={setFinishExport}
        setExportInProgress={setExportInProgress}
      />
      <Card className="card-container">
        <CardBody>
          <div className="card-title">
            <h5>Relatório de agendamentos</h5>
          </div>
          <div>
            <Row
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginBottom: 10,
                width: '50px',
              }}
            >
              <SectionComponent
                sectionName="Filtros"
                setIsOpen={setCollapseIsOpen}
                isOpen={collapseIsOpen}
              />
            </Row>
            <Collapse isOpen={collapseIsOpen}>
              <SearchFilters
                columns={columnsFilter}
                activeFilter={activeFilter}
                setActiveFilterHandle={setActiveFilterHandle}
              />
            </Collapse>
          </div>
          <div className="export-and-search-container">
            <Input
              className="search-input"
              type="text"
              placeholder="Pesquisar..."
              value={search}
              onChange={(e) => setSearch(e.target.value) > setCurrentPage(1)}
            />
            <Button
              color="success"
              onClick={() =>
                createXlsx({
                  eventId: eventId,
                  name: name,
                  countData: totalItems,
                  setFinishExport,
                }) > setExportInProgress(true)
              }
            >
              Exportar Relatório
            </Button>
          </div>
          <Table className="table mb-0 table">
            <thead className="table-light">
              <tr>
                <th>Nome do Participante</th>
                <th>Email</th>
                <th>Sala</th>
                <th>Data</th>
                <th>Horário</th>
              </tr>
            </thead>
            <tbody>
              {appointments.length > 0 &&
                appointments.map((item, index) => (
                  <tr key={index}>
                    <td>{item.participantName}</td>
                    <td>{item.email}</td>
                    <td>{item.sala}</td>
                    <td>{getDate(item.dataHoraInicio)}</td>
                    <td>
                      {formatDateTime(item.dataHoraInicio)} às{' '}
                      {formatDateTime(item.dataHoraFim)}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </CardBody>
      </Card>
      <div
        style={{ display: 'flex', alignSelf: 'flex-end', marginRight: '5%' }}
      >
        <Pagination
          itemsPerPage={itemsPerPage}
          setItemsPerPage={setItemsPerPage}
          totalItems={totalItems}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div>
  );
}
