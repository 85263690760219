export const data = [
  {
    imagePtEs: require('../../../assets/image/encontro-lideres/10h-pt-es.png'),
    imageEng: require('../../../assets/image/encontro-lideres/10h-en.png'),
    time: '10:00',
  },
  {
    imagePtEs: require('../../../assets/image/encontro-lideres/11h05-pt-es.png'),
    imageEng: require('../../../assets/image/encontro-lideres/11h05-en.png'),
    time: '11:05',
  },
  {
    imagePtEs: require('../../../assets/image/encontro-lideres/12h10-pt-es.png'),
    imageEng: require('../../../assets/image/encontro-lideres/12h10-en.png'),
    time: '12:10',
  },
];
