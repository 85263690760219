import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Label,
} from 'reactstrap';
import Select from 'react-select';
import { getStockActivities } from '../../../services/stockActivities';
import {
  createAppointment,
  createEditAppointment,
  getAppointmentsByLogin,
} from '../../../services/appointments';
import SweetAlert from 'react-bootstrap-sweetalert';

import Card from './Card';

import '../styles.css';
import logo from '../../../assets/image/encontro-lideres/logo.png';
import imgAgenda from '../../../assets/image/encontro-lideres/img-agenda.png';
import { FaExclamationCircle } from 'react-icons/fa';

import {
  convertTo12HourFormat,
  isValidUUID,
  outputDate,
} from '../../../utils/functions';

export default function EncontroLideresAgendaFotos() {
  const [participantId, setParticipantId] = useState('');
  const [participantName, setParticipantName] = useState('');
  const [email, setEmail] = useState(null);
  const [eventId, setEventId] = useState('');
  const [idioma, setIdioma] = useState('');
  const [eventName, setEventName] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showModalConsult, setShowModalConsult] = useState(false);
  const [time, setTime] = useState(null);
  const [timeList, setTimeList] = useState('');
  const [date, setDate] = useState(null);
  const [dateList, setDateList] = useState('');
  const [room, setRoom] = useState(null);
  const [roomList, setRoomList] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [successEdit, setSuccessEdit] = useState(false);
  const [errorEdit, setErrorEdit] = useState(false);
  const [theme, setTheme] = useState(null);
  const [timeStart, setTimeStart] = useState('');
  const [timeEnd, setTimeEnd] = useState('');
  const [stockActivities, setStockActivities] = useState([]);
  const [appointmentsMade, setAppointmentsMade] = useState([]);
  const [nomeAgendamento, setNomeAgendamento] = useState('lidera-2');

  const { t, i18n } = useTranslation();

  useEffect(() => {
    getParams();
  }, []);

  useEffect(() => {
    if (!showModal && eventId) {
      getStock(eventId);
      if (participantId) {
        getAppointments('', eventId, participantId);
      }
    }
  }, [showModal]);

  const toggle = (time) => {
    setShowModal(!showModal);
    // setTime(time);
    reset();
  };
  const toggleConsult = () => {
    setShowModalConsult(!showModalConsult);
  };
  const edit = (time) => {
    // setTime(time);
    setShowModal(!showModal);
    setEditMode(true);
  };

  function getParams() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (isValidUUID(urlParams.get('paxId'))) {
      setParticipantId(urlParams.get('paxId'));
    }
    setParticipantName(urlParams.get('paxName'));
    setEmail(urlParams.get('email'));
    setEventId(urlParams.get('eventId'));
    setEventName(urlParams.get('eventName'));
    getStock(urlParams.get('eventId'));
    setIdiom(urlParams.get('idiom'));
  }
  function setIdiom(idiom) {
    const lang =
      idiom === 'pt-br' || idiom === 'ptBr' || idiom?.toLowerCase() === 'pt'
        ? 'ptBr'
        : idiom === 'sp' || idiom === 'es'
        ? 'sp'
        : 'en';
    i18n.changeLanguage(lang);
    setIdioma(lang);
  }

  async function getStock(eventId) {
    const res = await getStockActivities(eventId);
    if (res?.total > 0) {
      const list = res.data.filter(
        (e) =>
          e.quantidadeMax > e.quantidadeUtilizada &&
          (e.sala === 'Sala Paineiras' || e.sala === 'Foyer Campinas')
      );
      setStockActivities(list);
      const transformDate = list.map((e) => ({
        label: outputDate(e.dataHoraInicio.substring(0, 10), i18n.language),
        value: e.dataHoraInicio.substring(0, 10),
      }));

      const listFiltered = transformDate.filter((item, index, self) => {
        return (
          self.findIndex((innerItem) => innerItem.label === item.label) ===
          index
        );
      });
      setDateList(listFiltered);
    }
  }

  async function getAppointments(
    time,
    eventId,
    participantId,
    nomeAgendamento
  ) {
    if (eventId && participantId && nomeAgendamento) {
      const res = await getAppointmentsByLogin(
        eventId,
        participantId,
        nomeAgendamento
      );
      if (res?.total > 0) {
        setAppointmentsMade(res?.data);

        return res?.data;
      }
    }
    return [];
  }
  const submit = () => {
    create();
  };

  async function create() {
    const data = {
      eventId: eventId,
      eventName: eventName,
      participantId: participantId,
      participantName: participantName,
      atividade: 'FOTO',
      sala: room,
      ativo: true,
      dataHoraInicio: timeStart,
      dataHoraFim: timeEnd,
      email: email,
      nomeAgendamento: nomeAgendamento,
      idioma: idioma,
    };

    const res = await createEditAppointment(data);

    if (res.message === 'CREATED') {
      setSuccessEdit(true);
    }
    if (res.message === 'NO_STOCK_AVAILABLE') {
      setErrorEdit(true);
    }
    toggle();
    setAppointmentsMade([]);
  }

  const reset = () => {
    setDate(null);
    setRoom(null);
    setTime(null);
    setEditMode(false);
  };

  const changeDate = (value, action, date) => {
    if (action === 'clear') {
      setDate(null);
    }

    if (action === 'select-option') {
      setDate(value);

      const filterTime = stockActivities.filter(
        (e) => e.dataHoraInicio.substring(0, 10) === date
      );
      const transformDate = filterTime.map((e) => ({
        label:
          t('Das ') +
          (i18n.language === 'en'
            ? convertTo12HourFormat(
                e.dataHoraInicio.split('T')[1].substring(0, 5)
              )
            : e.dataHoraInicio.split('T')[1].substring(0, 5)) +
          t(' as ') +
          (i18n.language === 'en'
            ? convertTo12HourFormat(e.dataHoraFim.split('T')[1].substring(0, 5))
            : e.dataHoraFim.split('T')[1].substring(0, 5)),
        value: e.dataHoraInicio,
      }));
      const listFiltered = transformDate.filter((item, index, self) => {
        return (
          self.findIndex((innerItem) => innerItem.label === item.label) ===
          index
        );
      });
      setTimeList(listFiltered);
    }
  };
  const changeTime = (value, action, time) => {
    if (action === 'clear') {
      setTime(null);
    }

    if (action === 'select-option') {
      setTime(value.split('T')[1].substring(0, 5));
      const filterTime = stockActivities.filter(
        (e) =>
          e.dataHoraInicio.split('T')[1].substring(0, 5) ===
            time.split('T')[1].substring(0, 5) &&
          e.dataHoraInicio.split('T')[0].substring(0, 10) === date
      );
      const transformList = filterTime.map((e) => ({
        label: e.sala,
        value: e.sala,
      }));
      const listFiltered = transformList.filter((item, index, self) => {
        return (
          self.findIndex((innerItem) => innerItem.label === item.label) ===
          index
        );
      });
      setRoomList(listFiltered);
      setTimeStart(filterTime[0]?.dataHoraInicio);
      setTimeEnd(filterTime[0]?.dataHoraFim);
    }
  };
  const changeRoom = (value, action, time) => {
    if (action === 'clear') {
      setRoom(null);
    }
    if (action === 'select-option') {
      setRoom(value);
    }
  };

  return (
    <main className="el-main-container">
      <header className="el-header">
        <img src={logo} alt="logo" />
      </header>
      {participantId ? (
        <div className="el-description">
          <Label className="el-description-title">
            {`${t(
              'Agende o seu horário para a sessão de fotos corporativas'
            )}!`}
          </Label>
          <p style={{ margin: 0 }}>
            {t(
              `Vocês terão a oportunidade de renovar suas fotos corporativas e atualizar suas redes e o slack! As empreendedoras da beleza estarão disponíveis no evento para realçar ainda mais a sua beleza e claro, contaremos com fotógrafos especializados em fotos profissionais para capturar seu melhor ângulo.`
            )}
          </p>
          <p style={{ margin: 0 }}>
            {t(
              `Realize seu agendamento clicando no relógio abaixo. Caso você não consiga agendar até 22/09, faremos isso por você de forma aleatória, mas todos terão um horário garantido. Lembre-se, é importante comparecer ao local e horário escolhido. Em caso de atraso, só conseguiremos atender se não houver fila de espera.`
            )}
          </p>
          <p>
            {t(
              `Após o encontro, disponibilizaremos todas as fotos através de um link para que você possa baixá-las facilmente.`
            )}
          </p>
        </div>
      ) : (
        <div className="el-description">
          <div className="el-warning-msg">
            <FaExclamationCircle size={40} />
            <p>
              {t(
                'Olá! Não identificamos o seu cadastro. Por favor, entre em contato com o suporte para ter acesso ao agendamento de breakroom'
              )}
              .
            </p>
          </div>
        </div>
      )}
      <section className="el-section">
        {eventId && participantId && (
          <Card
            eventId={eventId}
            participantId={participantId}
            time="10:00"
            toggle={toggle}
            appointments={getAppointments}
            status={showModal}
            edit={edit}
            t={t}
            idiom={i18n.language}
            nomeAgendamento={nomeAgendamento}
          />
        )}
      </section>

      <section className="el-description-legal">
        {eventId && participantId && (
          <p>
            {t(
              `No caso de atrasos da programação, considere o horário de término como início das sessões e baseie seu agendamento pelo seu intervalo de referência.`
            )}
          </p>
        )}
      </section>

      {successEdit && (
        <SweetAlert
          success
          title={`${t('Prepare-se para Brilhar!')}`}
          onConfirm={() => {
            setSuccessEdit(false);
          }}
        >
          {t('Sua sessão de fotos está reservada!')}.
        </SweetAlert>
      )}

      {errorEdit && (
        <SweetAlert
          warning
          title={t('Desculpe')}
          onConfirm={() => {
            setErrorEdit(false);
          }}
        >
          {t(`Agendamentos esgotados para essa sala`)}
        </SweetAlert>
      )}

      {showModal && (
        <>
          <Modal
            backdrop={false}
            centered={true}
            isOpen={showModal}
            toggle={() => toggle()}
          >
            <ModalHeader toggle={() => toggle()}>
              {t('Agendamento')}
            </ModalHeader>
            <ModalBody>
              <p style={{ margin: 0, fontWeight: '500' }}>
                {t(
                  'Selecione o dia que mais lhe convém para a sessão de fotos.'
                )}
              </p>
              <p
                style={{
                  fontStyle: 'italic',
                  fontSize: '11px',
                }}
              >
                {editMode &&
                  `${t(
                    'Para confirmar o novo agendamento, não esqueça de clicar em Salvar após fazer as alterações. Lembre-se, se você não salvá-lo, a programação original permanecerá inalterada.'
                  )}`}
              </p>
              <Row>
                <Col>
                  <label className="form-label" style={{ marginTop: '10px' }}>
                    {t('Escolha a Data:')}
                  </label>
                  <Select
                    options={dateList}
                    isClearable={true}
                    placeholder={t('Selecionar')}
                    onChange={(e, { action }) =>
                      changeDate(e?.value, action, e?.value)
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <label className="form-label" style={{ marginTop: '10px' }}>
                    {t('Escolha a Hora:')}
                  </label>
                  <Select
                    options={timeList}
                    isClearable={true}
                    placeholder={t('Selecionar')}
                    onChange={(e, { action }) =>
                      changeTime(e?.value, action, e?.value)
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <label className="form-label" style={{ marginTop: '10px' }}>
                    {t('Escolha a Sala:')}
                  </label>
                  <Select
                    options={roomList}
                    isClearable={true}
                    placeholder={t('Selecionar')}
                    onChange={(e, { action }) =>
                      changeRoom(e?.value, action, '')
                    }
                  />
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                onClick={submit}
                disabled={!date || !time || !room}
              >
                {t('Salvar')}
              </Button>
              <Button color="secondary" onClick={toggle}>
                {t('Cancelar')}
              </Button>
            </ModalFooter>
          </Modal>
        </>
      )}

      {showModalConsult && (
        <div>
          <Modal
            backdrop={false}
            centered={true}
            isOpen={showModalConsult}
            size="xl"
            toggleConsult={() => toggleConsult()}
          >
            <ModalHeader
              style={{ marginTop: '10px', height: '1px' }}
              toggle={() => toggleConsult()}
            >
              {t('Agenda')}
            </ModalHeader>
            <ModalBody>
              <Row style={{ textAlign: 'center' }}>
                <Col style={{ display: 'flex' }}>
                  <img
                    src={imgAgenda}
                    alt="agenda"
                    width="100%"
                    height="100%"
                  />
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter style={{ textAlign: 'center' }}>
              {/* <Button color="secondary" onClick={toggleConsult}>
                {t('Fechar')}
              </Button> */}
            </ModalFooter>
          </Modal>
        </div>
      )}
    </main>
  );
}
