import React, { useEffect } from "react";
import "../styled.css";
// componentes
import ItemRoomOption from "./itemRoomOption";

export default function ListAllRooms(props) {
  const { setStep, setRoomSelected, setIsSupplier, user } = props;

  const typeRooms = [
    "Circuito de Palestras - 1",
    "Circuito de Palestras - 2",
    "Circuito de Reuniões - One to One",
    "Espaço Diversa WOW Dubai",
    "Lounge Sense of África",
    "Ativação: What do you know about ...",
  ];

  return (
    <>
      <div className="div-items-list">
        {typeRooms.map((e, i) => (
          <div key={i} style={{ width: "100%" }}>
            <ItemRoomOption
              setStep={setStep}
              setRoomSelected={setRoomSelected}
              setIsSupplier={setIsSupplier}
              title={e}
              user={user}
            />
          </div>
        ))}
      </div>
    </>
  );
}
