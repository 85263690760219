import React, { useState } from 'react';
import { Spinner } from 'reactstrap';
import { formatDate } from '../../utils/functions';
import '../../styles/style.css';

function ModalConfirmation(props) {
  const { confirm, toggle, room, timeStart, timeEnd } = props;
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div className="screen-blocker">
      {!isLoading ? (
        <div className="modal-confirmation-container">
          <p>Confirmar Agendamento?</p>
          <div className="modal-confirmation-container-subtitle">
            <p>Data: {formatDate(timeStart.split('T')[0])} </p>
            <p>
              Hora: {timeStart.split('T')[1].substring(0, 5)} às{' '}
              {timeEnd.split('T')[1].substring(0, 5)}{' '}
            </p>
            <p>Local: {room}</p>
          </div>
          <div className="modal-confirmation-button-container">
            <div
              className="modal-confirmation-button button-color-green"
              onClick={() => {
                setIsLoading(true);
                confirm();
              }}
            >
              Sim
            </div>
            <div
              className="modal-confirmation-button button-color-lightgray"
              onClick={() => toggle()}
            >
              Não
            </div>
          </div>
        </div>
      ) : (
        <div className="container-modal-spinner">
          <Spinner className="spinner-in-modal" />
        </div>
      )}
    </div>
  );
}

export default ModalConfirmation;
