import React, { useState } from "react";
import ModalConfirmation from "../pages/Ntt/ModalConfirmation";
import ModalMessage from "../pages/senac/ModalMessage";
import { createAppointment } from "../services/appointments";
import { formatTime } from "../utils/functions";
import { FaCheckCircle, FaExclamationCircle } from "react-icons/fa";

import "../styles/style.css";
function CardTimeActivity(props) {
  const { activity, eventId, participantId, room, timeStart, timeEnd } = props;
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showModalMessage, setShowModalMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [title, setTitle] = useState("");
  const [icon, setIcon] = useState("");

  async function create() {
    const data = {
      eventId: eventId,
      participantId: participantId,
      atividade: activity,
      sala: room,
      ativo: true,
      dataHoraInicio: timeStart,
      dataHoraFim: timeEnd,
    };
    const res = await createAppointment(data);

    if (res.message === "CREATED") {
      setTitle("Confirmação");
      setMessage("Horário agendado!");
      setIcon(<FaCheckCircle className="icon-check" />);
    }
    if (res.message === "APPOINTMENT_MADE") {
      setTitle("Erro!");
      setMessage("Horário já foi agendado.");
      setIcon(<FaExclamationCircle className="icon-exclamation" />);
    }
    if (res.message === "NO_STOCK_AVAILABLE") {
      setTitle("Erro!");
      setMessage("Horário Indisponível.");
      setIcon(<FaExclamationCircle className="icon-exclamation" />);
    }
    if (res.message === "LOGIN_INVALID") {
      setTitle("Erro!");
      setMessage("Usuário inválido.");
      setIcon(<FaExclamationCircle className="icon-exclamation" />);
    }
    if (res.message === "EVENT_INVALID") {
      setTitle("Erro!");
      setMessage("Evento inválido.");
      setIcon(<FaExclamationCircle className="icon-exclamation" />);
    }
    setShowConfirmation(!showConfirmation);
    setShowModalMessage(!showModalMessage);
  }

  function handleConfirmation(event) {
    // event.stopPropagation();
    window.scrollTo({ top: 0, behavior: "smooth" });
    document.body.style.overflow = "hidden";
    setShowConfirmation(!showConfirmation);
  }

  function showModal() {
    setShowConfirmation(!showConfirmation);
    document.body.style.overflow = "scroll";
  }

  function modalMessageShow() {
    setShowModalMessage(!showModalMessage);
    document.body.style.overflow = "scroll";
    if (title !== "Erro!") {
      window.location.reload(true);
    }
  }

  async function confirmAppointment() {
    await create();
  }

  return (
    <>
      <div>
        {showModalMessage && (
          <ModalMessage
            toggle={() => modalMessageShow()}
            message={message}
            title={title}
            icon={icon}
          />
        )}
        {showConfirmation ? (
          <div>
            <ModalConfirmation
              confirm={() => confirmAppointment()}
              toggle={() => showModal()}
              room={room}
              timeStart={timeStart}
              timeEnd={timeEnd}
            />
          </div>
        ) : (
          <div
            className="card-time-activity"
            onClick={(e) => handleConfirmation(e)}
          >
            <p>{`Das ${formatTime(timeStart)} as ${formatTime(timeEnd)}`}</p>
            <p style={{ fontSize: "16px" }}>{`Sala: ${room}`}</p>
          </div>
        )}
      </div>
    </>
  );
}

export default CardTimeActivity;
