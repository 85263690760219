import React, { useState, useEffect } from 'react';
import { Button, Label } from 'reactstrap';
import '../styles.css';

export default function Card(props) {
  const { edit, imagePtEs, imageEn, time, toggle, appointments, t, idiom } =
    props;
  const [data, setData] = useState([]);
  const [init, setInit] = useState(false);

  useEffect(() => {
    getAppointments();
  }, []);

  useEffect(() => {
    if (!props.status) {
      getAppointments();
    }
  }, [props.status]);

  const getAppointments = async () => {
    const res = await appointments(
      props.time,
      props.eventId,
      props.participantId
    );
    if (res.length > 0) {
      setData(res);
    }
    setInit(true);
  };
  return init ? (
    <>
      {data.length > 0 ? (
        <div className="el-card-container-edit-main">
          <div className="el-card-container-edit-text" onClick={() => ''}>
            <Label>{t('Agendamento selecionado')}</Label>
            <p>
              {t('Sala')}: {data[0].sala}
            </p>
            <p>
              {t('Tema')}: {data[0].atividade}
            </p>
            <p>
              {t('Horário')}: {time}
            </p>
          </div>
          <div className="el-card-container-edit-button" onClick={() => ''}>
            <Button
              color="secondary"
              className="el-card-edit-button"
              onClick={() => edit(time)}
            >
              {t('Editar')}
            </Button>
          </div>
        </div>
      ) : (
        <div className="el-card-container" onClick={() => toggle(time)}>
          <img
            src={idiom !== 'En'.toLowerCase() ? imagePtEs : imageEn}
            alt="agenda"
          />
        </div>
      )}
    </>
  ) : (
    <>
      <div className="el-card-container-draft"></div>
    </>
  );
}
