import React from "react";
import ItemRoomOption from "./itemRoom";
import "../styled.css";

export default function ListAllRooms({
  setStep,
  setRoomSelected,
  setIsSupplier,
  user,
  allRoomsSaved
}) {
  const rooms = [
    "Sala 1",
    "Sala 2",
    "CIRCUITO DE REUNIÕES",
    "Espaço Diversa WOW Dubai",
    "Lounge Sense of África",
    "Ativação: What do you know about ...",
  ];

  return (
    <div className="div-items-list">
      {rooms.map((room, index) => (
        <div key={index} style={{ width: "100%" }}>
          <ItemRoomOption
            title={room}
            setStep={setStep}
            setRoomSelected={setRoomSelected}
            setIsSupplier={setIsSupplier}
            user={user}
            allRoomsSaved={allRoomsSaved}
          />
        </div>
      ))}
    </div>
  );
}
