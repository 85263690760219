import React, { useState, useEffect } from "react";
import { Card, CardTitle, CardBody, Row, Button } from "reactstrap";
import { useSearchParams } from "react-router-dom";
import "./styled.css";
// Componentes
import ListAllRooms from "./listRooms";
import ListSavedRooms from "./listSaved";
import ListTimeRoom from "./listTimeRoom";

const HeaderButtons = ({ step, setStep, isSupplier }) => {
  const buttons = {
    1: (
      <Button
        onClick={() => setStep(2)}
        className="btn btn-success"
        style={buttonStyle}
      >
        Ver agenda <i className="bx bx-list-ol" style={iconStyle} />
      </Button>
    ),
    2: (
      <Button
        onClick={() => setStep(1)}
        className="btn btn-success"
        style={buttonStyle}
      >
        Ver meus agendamentos{" "}
        <i className="bx bx-check-circle" style={iconStyle} />
      </Button>
    ),
    3: (
      <Button
        onClick={() => setStep(isSupplier ? 4 : 2)}
        className="btn btn-success"
        style={buttonStyle}
      >
        {isSupplier ? "Voltar para Fornecedores" : "Voltar para agenda"}{" "}
        <i className="bx bx-list-ol" style={iconStyle} />
      </Button>
    ),
    4: (
      <Button
        onClick={() => setStep(2)}
        className="btn btn-success"
        style={buttonStyle}
      >
        Voltar para agenda <i className="bx bx-list-ol" style={iconStyle} />
      </Button>
    ),
  };

  return <>{buttons[step]}</>;
};

const buttonStyle = {
  width: "auto",
  display: "flex",
  alignItems: "center",
  backgroundColor: "#1cbe1c",
};

const iconStyle = { fontSize: 19, marginLeft: 5 };

const titles = {
  1: "Meus Agendamentos",
  2: "Agenda",
  3: "Horários da Sala",
  4: "Fornecedores",
};

export default function DsWorld() {
  const [step, setStep] = useState(1);
  const [user, setUser] = useState(null);
  const [roomSelected, setRoomSelected] = useState(null);
  const [isSupplier, setIsSupplier] = useState(false);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const idEvent = searchParams.get("idEvent");
    const idPart = searchParams.get("idPart");

    if (idEvent && idPart) {
      setUser({ idEvent, idPart });
    }
  }, [searchParams]);

  return (
    <section className="section-dsword">
      <Card className="card-dsword">
        <CardBody>
          <Row className="justify-content-end">
            <HeaderButtons
              step={step}
              setStep={setStep}
              isSupplier={isSupplier}
            />
          </Row>

          <CardTitle className="mt-4">{titles[step]}</CardTitle>

          {step === 1 && <ListSavedRooms user={user} />}
          {step === 2 && (
            <ListAllRooms
              setStep={setStep}
              setRoomSelected={setRoomSelected}
              setIsSupplier={setIsSupplier}
              user={user}
            />
          )}
          {step === 3 && (
            <ListTimeRoom
              roomSelected={roomSelected}
              user={user}
              setStep={setStep}
            />
          )}
        </CardBody>
      </Card>
    </section>
  );
}
