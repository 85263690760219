export function formatDate(date) {
  const [year, month, day] = date.split('-');
  return `${day}/${month}/${year}`;
}

export function formatTime(date) {
  return date.substr(11, 5);
}

export function extractDate(date) {
  return date.substr(0, 10);
}

export function getToday() {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

export function getDiffMinutes(date) {
  const now = new Date();
  const dateAppointment = new Date(date.substring(0, 19));
  const diff = (dateAppointment.getTime() - now.getTime()) / 1000;
  return Math.abs(Math.round(diff / 60));
}

export function convertTo12HourFormat(time24hr) {
  const [hours, minutes] = time24hr.split(':').map(Number);

  if (
    isNaN(hours) ||
    isNaN(minutes) ||
    hours < 0 ||
    hours >= 24 ||
    minutes < 0 ||
    minutes >= 60
  ) {
    return 'Invalid time format';
  }

  const period = hours < 12 ? 'am' : 'pm';
  const hour12 = hours === 0 ? 12 : hours <= 12 ? hours : hours - 12;
  const formattedTime = `${String(hour12).padStart(2, '0')}:${String(
    minutes
  ).padStart(2, '0')} ${period}`;
  return formattedTime;
}

export function isValidUUID(uuidStr) {
  const uuidRegex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
  return uuidRegex.test(uuidStr);
}

export function outputDate(inputDate, lang) {
  const parts = inputDate.split('-');
  if (lang === 'en') {
    return `${parts[1]}/${parts[2]}/${parts[0]}`;
  }
  return `${parts[2]}/${parts[1]}/${parts[0]}`;
}