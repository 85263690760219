import React from "react";
import { FaCalendarAlt } from "react-icons/fa";
import { formatDate } from "../utils/functions";
import { formatTime } from "../utils/functions";

import "../styles/style.css";

function CardSchedule(props) {
  const {
    enabled,
    appointmentMade,
    checkAllApointments,
    expires,
    avalilableActivities,
  } = props;
  return (
    <div>
      <div
        className={
          enabled && checkAllApointments && !expires && avalilableActivities
            ? "card-schedule"
            : "card-schedule-disabled"
        }
      >
        <FaCalendarAlt
          className={
            enabled && checkAllApointments && !expires && avalilableActivities
              ? "card-schedule-icon"
              : "card-schedule-icon-disabled"
          }
        />
        <p>{formatDate(props.date)}</p>
        <div
          className={
            enabled && checkAllApointments && !expires && avalilableActivities
              ? "card-schedule-button"
              : "card-schedule-button-disabled"
          }
        >
          {expires || !avalilableActivities ? (
            <div>
              <p>Agendamentos Encerrados</p>
            </div>
          ) : !checkAllApointments && !appointmentMade ? (
            <div>
              <p>Agendamentos Esgotados</p>
            </div>
          ) : appointmentMade ? (
            <div className="card-appointed">
              <p style={{ fontWeight: "bold" }}>Agendamento:</p>
              <p>{`Atividade: ${appointmentMade[1][0].atividade}`}</p>
              <p>{`Horário: ${formatTime(
                appointmentMade[1][0].dataHoraInicio
              )} às ${formatTime(appointmentMade[1][0].dataHoraFim)}`}</p>
              <p>{`Local: Sala ${appointmentMade[1][0].sala}`}</p>
            </div>
          ) : (
            <p>Escolha sua atividade</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default CardSchedule;
