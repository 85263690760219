import React, { useEffect, useState } from 'react';
import CardTimeActivity from '../../components/CardTimeActivity';

import '../../styles/style.css';

function ModalActivityDates(props) {
  const { participantId, toggle, data } = props;
  const [activityDates, setActivityDates] = useState([]);
  const [show, setShow] = useState(false);

  useEffect(() => {
    setActivityDates(props.data);
    setShow(true);
  }, []);

  return (
    <div
      className={
        show ? 'side-drawer-time-activity open' : 'side-drawer-time-activity'
      }
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <i
          className="mdi mdi-chevron-left close-menu"
          style={{
            fontSize: '50px',
          }}
          onClick={() => {
            setShow(false);
            toggle();
          }}
        />
        <p style={{ marginTop: '12px', fontSize: '1rem' }}>
          Voltar para a lista de Atividades
        </p>
      </div>
      <div style={{ textAlign: 'center', fontSize: '1.3rem' }}>
        <p>Escolha o Horário da Atividade</p>
      </div>

      <div style={{ marginTop: '2rem' }}>
        {activityDates.map((e) => (
          <div>
            <CardTimeActivity
              timeStart={e.dataHoraInicio}
              timeEnd={e.dataHoraFim}
              room={e.sala}
              eventId={e.eventId}
              activity={e.atividade}
              participantId={participantId}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default ModalActivityDates;
