import React, { useEffect, useState } from 'react';
import CardSchedule from '../../components/CardSchedule';
import CardScheduleBlank from '../../components/CardScheduleBlank';
import ModalActivities from '../senac/ModalActivities';
import { getAppointmentsByLogin } from '../../services/appointments';
import { getStockActivities } from '../../services/stockActivities';
import { extractDate, getToday, getDiffMinutes } from '../../utils/functions';

import '../../styles/style.css';

const dates = [
  '2023-08-21',
  '2023-08-22',
  '2023-08-23',
  // '2023-08-25',
  // '2023-08-26',
];

function ListAvailableDatesPresentation(props) {
  const { eventId, participantId } = props;
  const [isOpenModalActivities, setIsOpenModalActivities] = useState(false);
  const [datesAppointed, setDatesAppointed] = useState([]);
  const [appointmentMade, setAppointmentMade] = useState('');
  const [dateActivity, setDateActivity] = useState('');
  const [init, setInit] = useState(false);
  const [stockActivities, setStockActivities] = useState([]);
  const [filterDate, setFilterDate] = useState([]);

  function getByDate(date) {
    return stockActivities.filter(
      (item) => extractDate(item.dataHoraInicio) === date
    );
  }

  function getEnabledDate(date) {
    return [...datesAppointed].includes(date);
  }

  function listLoadingCards() {
    return dates.map((e, i) => (
      <div class={`waiting-for-card1`} key={e}>
        <CardScheduleBlank date={e} />
      </div>
    ));
  }

  const groupByColumn = (arr, column) => {
    return arr.reduce((grouped, item) => {
      const key = item[column];
      return {
        ...grouped,
        [key]: [...(grouped[key] || []), item],
      };
    }, {});
  };

  async function appointmentsFilter(date) {
    const res = await getAppointmentsByLogin(eventId, participantId);
    if (res.data) {
      const groupedData = groupByColumn(res.data, 'dataHoraFim');
      setDatesAppointed(
        new Set(Object.entries(groupedData).map((e) => extractDate(e[0])))
      );
      setAppointmentMade(
        Object.entries(groupedData).filter((e) => extractDate(e[0]))
      );
    }
    setInit(true);
  }

  function getAppointmentMageByDate(date) {
    return appointmentMade.filter((e) => extractDate(e[0]) === date)[0];
  }

  function checkAllApointmentsByDate(date) {
    const filterByData = getByDate(date);
    const stockFull = filterByData.map(
      (e) => e.quantidadeUtilizada < e.quantidadeMax
    );
    return stockFull.filter((el) => el === true).length > 0;
  }

  function avalilableActivities(date) {
    const filterByData = getByDate(date);
    const checkAvalilable = filterByData.filter(
      (e) => getDiffMinutes(e.dataHoraInicio) >= 30
    );
    return checkAvalilable.length > 0;
  }
  function checkExpires(date) {
    const formattedDate = getToday();
    return new Date(formattedDate) > new Date(date);
  }

  function handleClick(date) {
    const enabled = getEnabledDate(date);
    const checkAll = checkAllApointmentsByDate(date);
    const expires = checkExpires(date);
    const checkAvalilableActivities = avalilableActivities(date);
    if (!enabled && checkAll && !expires && checkAvalilableActivities) {
      setDateActivity(date);
      setIsOpenModalActivities(!isOpenModalActivities);
    }
  }

  async function getStock() {
    const res = await getStockActivities(eventId);
    if (res.data) {
      setStockActivities(res.data);
    }
  }

  function handleClickModal(date) {
    setDateActivity(date);
    setIsOpenModalActivities(!isOpenModalActivities);
  }

  useEffect(() => {
    getStock();
    appointmentsFilter();
  }, []);

  useEffect(() => {
    if (!isOpenModalActivities) {
      getStock();
      appointmentsFilter();
    }
  }, [isOpenModalActivities]);

  return (
    <>
      {isOpenModalActivities ? (
        <ModalActivities
          isOpen={isOpenModalActivities}
          toggle={() => handleClickModal()}
          data={getByDate(dateActivity)}
          participantId={participantId}
          date={dateActivity}
          eventId={eventId}
        />
      ) : (
        <div className="rules-text">
          {/* <div>
            <p>
              O Espaço Zen oferece serviços com profissionais capacitados pelo
              Senac e foi pensado para o seu bem-estar e relaxamento. Agende o
              seu horário.
            </p>

            <p style={{ fontWeight: 'bold' }}>
              Regras de utilização dos serviços:
            </p>
            <div className="rules-text-body">
              <p>
                - Será permitido o agendamento de 1 serviço por dia por pessoa.
              </p>
              <p>
                - Não são permitidos alterações e/ou cancelamentos dos serviços
                já escolhidos.
              </p>
              <p>
                - Serão tolerados atrasos de até 5 minutos, após esse período o
                serviço será cancelado automaticamente e o beneficio será
                perdido (não será permitido um novo agendamento em
                substituição).
              </p>
              <p>
                - Os agendamentos serão realizados exclusivamente por essa
                página Para o serviço de cabelo/escola, o participante deve
                estar com os cabelos lavados e preferencialmente úmidos.
              </p>

              <div className="rules-text-footer-title">
                <p style={{ fontWeight: 'bold' }}>DURAÇÃO DOS ATENDIMENTOS:</p>
                <div>
                  <p></p>
                  <p>Massoterapia : 30 minutos</p>
                  <p>Auriculoterapia: 15 minutos</p>
                  <p>Maquiagem: 50 minutos</p>
                  <p>Cabelo/escova: 50 minutos</p>
                </div>
              </div>
            </div>
          </div> */}

          {init &&
            dates
              // .filter((element) => filterDate.includes(element))
              .map((item, i) => (
                <div onClick={() => handleClick(item)}>
                  <CardSchedule
                    date={item}
                    key={i}
                    data={stockActivities}
                    enabled={!getEnabledDate(item)}
                    appointmentMade={getAppointmentMageByDate(item)}
                    checkAllApointments={checkAllApointmentsByDate(item)}
                    avalilableActivities={avalilableActivities(item)}
                    expires={checkExpires(item)}
                  />
                </div>
              ))}
          {!init && <div>{listLoadingCards()}</div>}
        </div>
      )}
    </>
  );
}

export default ListAvailableDatesPresentation;
