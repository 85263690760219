import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';

import '../../styles/style.css';

function ModalMessage(props) {
  const { title, message, toggle, icon } = props;

  return (
    <div className="screen-blocker">
      <div className="modal-confirmation-container">
        <p style={{ fontSize: '1.5rem' }}>{title}</p>
        <div style={{ textAlign: 'center' }}>{icon}</div>
        <div className="modal-message-button-container">
          <p>{message}</p>
          <div
            className="modal-confirmation-button button-color-lightgray"
            onClick={() => toggle()}
          >
            Fechar
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalMessage;
