import React from "react";
import "../styled.css";

export default function ItemSupplierOption(props) {
  const { setStep, title, setRoomSelected } = props;

  return (
    <>
      <div
        className="div-card-item"
        onClick={() => setRoomSelected(title) > setStep(3)}
      >
        <div
          className="div-data-list"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <a>{title} - Ver horários</a>
          <i
            className="bx bxs-right-arrow-circle icon-save-list"
            style={{ backgroundColor: "white", color: "green" }}
          />
        </div>
      </div>
    </>
  );
}
