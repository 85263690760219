export const suppliers = [
  "Circuitos Europeus com Special Tours",
  "Caribe com Grupo Posadas",
  "Estados Unidos - Washington D.C",
  "Caribe com Hilton All Inclusive",
  "Caribe com Iberostar",
  "Caribe com Playa Resorts",
  "Estados Unidos com South Beach Group Miami",
  "Caribe com Palace Resorts",
  "Maldivas com Heritance e Adaaran",
  "Egito com Exotic Travel",
  "Grécia com Renaissance Tours & Travel",
  "Itália com Carrani",
  "Circuitos Europeus com Surland",
  "Voe ITA Airways",
  "Turismo do Nepal & Butão",
  "Turismo da Jamaica",
  "Turismo de Aruba com  Vitrine Global",
  "Estados Unidos - Visit Orlando",
  "Reino Unido com ATS Group",
  "Argentina com Quality Travel",
  "Chile com CTS",
  "Voe SKY Airlines",
  "Colômbia com All Reps",
  "Peru com AVIPAC",
  "Voe TAP Airlines",
  "Espanha - Turespanha",
  "Estados Unidos - Santa Monica Travel",
  "Turquia com Crystal Concepts",
  "Viagens de Motorhomes com Cruise America",
  "TBD - Diversa 01",
  "Estados Unidos - Fort Lauderdale",
  "Marrocos Royal Air Marroc & Visit Marroco",
  "TBD - Diversa 02",
  "Panamá & Copa Airlines",
  "R11 Royal Caribbean",
  "Estados Unidos - Greater Miami",
  "TBD - Diversa 03",
  "Estados Unidos - Travel South",
  "Estados Unidos - San Diego",
  "Hero Seguros",
  "Estados Unidos - St Pete & Clearwater",
  "Caribe com Palladium Resorts",
  "Cruzeiros com NCL Norwegian Cruise Line",
  "Coris Seguros",
  "Espanha Catalunya com Catalan Tourist Board",
  "Locação de carros com Alamo Rental Cars",
  "Estados Unidos - Nova York Tourism and Convention",
  "Turismo do Egito",
  "TBD - Diversa 04",
  "Caribe com RCD Hotels",
  "Voe American Airlines",
  "Israel com Conexion Travel Sar-El",
  "Chile com Sernatur",
  "Estados Unidos com Brand USA",
  "Dubai com Turismo de Dubai",
  "Caribe com Decameron Resorts",
  "China com Enjoy China Tour",
  "Estados Unidos - Hudson Yards & The Edge NYC",
  "TBD - Diversa 05",
  "TBD - Diversa 06",
];
