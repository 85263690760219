import axios from "axios";

async function getScheduleSaved(idEvent, idPart) {
  const config = {
    method: "get",
    url: `https://gateway-admin-plus.sistemasinteegra.com.br/api-admin-mobile/temp/agendamentos/${idEvent}/event/${idPart}/participant`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

async function getScheduleTimes(idEvent, room) {
  const config = {
    method: "get",
    url: `https://gateway-admin-plus.sistemasinteegra.com.br/api-admin-mobile/temp/estoqueatividades/${idEvent}/event/${room}/room`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

async function createSchedule(data) {
  const config = {
    method: "post",
    url: `https://gateway-admin-plus.sistemasinteegra.com.br/api-admin-mobile/temp/agendamentos`,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

async function deleteSchedule(id) {
  const config = {
    method: "delete",
    url: `https://gateway-admin-plus.sistemasinteegra.com.br/api-admin-mobile/temp/agendamentos/${id}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

export { getScheduleSaved, getScheduleTimes, createSchedule, deleteSchedule };
