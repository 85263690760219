import React, { useEffect, useState } from 'react';
import CardActivity from '../../components/CardActivity';
import ModalActivityDates from '../../pages/senac/ModalActivityDates';
import { getStockActivities } from '../../services/stockActivities';
import { extractDate, getDiffMinutes } from '../../utils/functions';

import '../../styles/style.css';

function ModalActivities(props) {
  const { date, eventId, participantId, toggle } = props;
  const [activities, setIsActivities] = useState([]);
  const [activitiesDates, setActivitiesDates] = useState([]);
  const [show, setShow] = useState(false);
  const [isOpenModalActivityDates, setIsOpenModalActivityDates] =
    useState(false);

  const groupByColumn = (arr, column) => {
    return arr.reduce((grouped, item) => {
      const key = item[column];
      return {
        ...grouped,
        [key]: [...(grouped[key] || []), item],
      };
    }, {});
  };

  function checkStock(data) {
    const dataFiltered = data.filter(
      (e) => e.quantidadeUtilizada < e.quantidadeMax
    );
    return dataFiltered;
  }

  function checkAvalilableTime(data) {
    const dataFiltered = data.filter(
      (e) => getDiffMinutes(e.dataHoraInicio) >= 30
    );
    return dataFiltered;
  }

  async function getStock() {
    const res = await getStockActivities(eventId);
    if (res.data) {
      const dataFilter = res.data.filter(
        (item) => extractDate(item.dataHoraInicio) === date
      );
      const stock = checkStock(dataFilter);
      const filterTime = checkAvalilableTime(stock);
      setIsActivities(groupByColumn(filterTime, 'atividade'));
    }
  }
  useEffect(() => {
    const stock = checkStock(props.data);
    const filterTime = checkAvalilableTime(stock);
    setIsActivities(groupByColumn(filterTime, 'atividade'));
    setShow(true);
  }, []);

  useEffect(() => {
    if (!isOpenModalActivityDates) {
      getStock();
    }
  }, [isOpenModalActivityDates]);

  function handleActivity(data) {
    setActivitiesDates(data);
    setIsOpenModalActivityDates(!isOpenModalActivityDates);
  }

  function handleClick() {
    setIsOpenModalActivityDates(!isOpenModalActivityDates);
  }

  return (
    <>
      {isOpenModalActivityDates ? (
        <ModalActivityDates
          isOpen={isOpenModalActivityDates}
          toggle={() => handleClick()}
          data={activitiesDates}
          participantId={participantId}
        />
      ) : (
        <div className={show ? 'side-drawer open' : 'side-drawer'}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <i
              className="mdi mdi-chevron-left close-menu"
              style={{
                fontSize: '50px',
              }}
              onClick={() => {
                setShow(false);
                toggle();
              }}
            />
            <p style={{ marginTop: '12px', fontSize: '1rem' }}>
              Voltar para o Espaço Zen
            </p>
          </div>

          <div style={{ textAlign: 'center', fontSize: '1.3rem' }}>
            <p>Escolha uma Atividade</p>
          </div>
          <div style={{ marginTop: '2rem' }}>
            {Object.entries(activities).map((e) => (
              <div onClick={() => handleActivity(e[1])}>
                <CardActivity name={e[0]} />
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
}

export default ModalActivities;
