import React, { useState } from 'react'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'

export default function Pagination({
  itemsPerPage,
  setItemsPerPage,
  totalItems,
  currentPage,
  setCurrentPage,
}) {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const slicePageBtns = () => {
    if (currentPage > totalPages - 2) {
      return [totalPages - 3, totalPages];
    }
    return [currentPage - 1, currentPage + 2];
  }

  const dropOptions = [10, 25, 50, 100];
  return (
    <div>
      <div>
        <div className="pagination-btns-container">
          <Dropdown 
            toggle={() => { setDropdownOpen(!dropdownOpen) }}
            isOpen={dropdownOpen}
            style={{ marginRight: "5px", backgroundColor: '#556ee6' }}
            >
            <DropdownToggle
              className="pagination-dropdown"
              style={{ backgroundColor: '#556ee6' }}
              caret
            >
              {itemsPerPage} Itens por página
            </DropdownToggle>
            <DropdownMenu>
              {dropOptions.map((option, index) => (
                <DropdownItem
                  key={index}
                  onClick={() => setItemsPerPage(option) > setCurrentPage(1)}
                >
                  {option}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
          <button
            className="pagination-btn"
            type="button"
            disabled={currentPage === 1}
            onClick={() => setCurrentPage(currentPage - 1)}
          >
            <i
              className="fas fa-arrow-left"
            />
          </button>
          {totalPages > 3
            ? Array.from(Array(totalPages).keys())
              .slice(...slicePageBtns())
              .map((page) => (
                <button
                  className={`page-number ${page + 1 === currentPage ? 'active' : ''}`}
                  key={page}
                  onClick={() => setCurrentPage(page + 1)}
                >
                  <span>
                    {page + 1}
                  </span>
                </button>
              ))
            : [...Array(totalPages)].map((_page, index) => (
            <button
              className={`page-number ${index + 1 === currentPage ? 'active' : ''}`}
              key={index}
              onClick={() => setCurrentPage(index + 1)}
            >
              <span>
                {index + 1}
              </span>
            </button>
          ))}
          <button
            className="pagination-btn"
            type="button"
            disabled={currentPage === totalPages || totalPages === 0}
            onClick={() => setCurrentPage(currentPage + 1)}
          >
            <i
              className="fas fa-arrow-right"
            />
          </button>
        </div>
      </div>
      <div style={{fontSize: '12px', width: '100%', display: 'flex', justifyContent: 'space-between', marginBottom: '20px'}}>
        <span>
          {`Total de itens ${totalItems}`}
        </span>
        <span>
          {`Total de páginas ${totalPages}`}
        </span>
      </div>
    </div>
  )
}
