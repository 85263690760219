import React, { useEffect, useState } from "react";
import { getScheduleTimes } from "../../../services/diversaTurismo";
import "../styled.css";

export default function ItemRoomOption({
  setStep,
  title,
  setRoomSelected,
  setIsSupplier,
  user,
  allRoomsSaved,
}) {
  const [show, setShow] = useState(false);
  const [timeRoomSelected, setTimeRoomSelected] = useState(null);

  useEffect(() => {
    async function fetchScheduleTimes() {
      const data = await getScheduleTimes(user.idEvent, title);
      if (data.total > 0) {
        setShow(data.total > 0);
        var search = allRoomsSaved.find((e) => e.sala === title);

        if (search) {
          setTimeRoomSelected(search);
        }
      }
    }

    fetchScheduleTimes();
  }, [title, user]);

  const handleClick = () => {
    if (timeRoomSelected) return;

    setRoomSelected(title);
    setStep(3);
    setIsSupplier(false);
  };

  if (!show) return null;

  return (
    <div
      className="div-card-item"
      style={{ backgroundColor: timeRoomSelected ? "#8aff8a" : "white" }}
      onClick={handleClick}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          padding: "4px",
        }}
      >
        {/* <a>{`${title} - Ver horários`}</a> */}
        <div>
          <a style={{ fontWeight: "bold" }}>{`${title}`}</a>
          <a>
            {timeRoomSelected
              ? ": " + timeRoomSelected.atividade
              : " - Ver horários"}
          </a>
        </div>
        <i
          className={
            timeRoomSelected
              ? "bx bx-check-circle icon-save-list"
              : "bx bxs-right-arrow-circle icon-save-list"
          }
        />
      </div>
    </div>
  );
}
