import React, { useState, useEffect } from 'react';
import { Button, Label } from 'reactstrap';
import '../styles.css';
// import imagePtEs from '../../../assets/image/encontro-lideres/10h-pt-es.png';
// import imageEn from '../../../assets/image/encontro-lideres/10h-en.png';
import image_clock from '../../../assets/image/encontro-lideres/icon-schedule-fotos.png'
import { convertTo12HourFormat, outputDate } from '../../../utils/functions';

export default function Card(props) {
  const {
    edit,
    time,
    toggle,
    appointments,
    t,
    idiom,
    status,
    nomeAgendamento,
  } = props;
  const [data, setData] = useState([]);
  const [init, setInit] = useState(false);

  useEffect(() => {
    getAppointments();
  }, []);

  useEffect(() => {
    if (!props.status) {
      getAppointments();
    }
  }, [props.status]);

  const getAppointments = async () => {
    const res = await appointments(
      props.time,
      props.eventId,
      props.participantId,
      props.nomeAgendamento
    );
    if (res.length > 0) {
      setData(res);
    }
    setInit(true);
  };
  return init ? (
    <>
      {data.length > 0 ? (
        <div className="el-card-container-edit-main">
          <div
            className="el-card-container-edit-text"
            style={{ paddingBottom: '20px' }}
            onClick={() => ''}
          >
            <Label>{t('Agendamento selecionado')}</Label>
            <p>
              {t('Sala')}: {data[0].sala}
            </p>
            <p>
              {t('Data')}:{' '}
              {outputDate(data[0].dataHoraInicio.substring(0, 10), idiom)}
            </p>
            <p>
              {t('Horário')}:{' '}
              {idiom === 'en'
                ? convertTo12HourFormat(
                    data[0].dataHoraInicio.split('T')[1].substring(0, 5)
                  )
                : data[0].dataHoraInicio.split('T')[1].substring(0, 5)}
              {t(' as ')}
              {idiom === 'en'
                ? convertTo12HourFormat(
                    data[0].dataHoraFim.split('T')[1].substring(0, 5)
                  )
                : data[0].dataHoraFim.split('T')[1].substring(0, 5)}
            </p>
          </div>
          {/* <div className="el-card-container-edit-button" onClick={() => ''}>
            <Button
              color="secondary"
              className="el-card-edit-button"
              onClick={() => edit(time)}
            >
              {t('Editar')}
            </Button>
          </div> */}
        </div>
      ) : (
        <div className="el-card-container" onClick={() => toggle(time)}>
          <img src={image_clock} alt="agenda" />
        </div>
      )}
    </>
  ) : (
    <>
      <div className="el-card-container-draft"></div>
    </>
  );
}
