import React, { useState, useEffect } from "react";
import ItemTimeOption from "./itemTimeRoom";
import { getScheduleTimes } from "../../../services/diversaTurismo";
import "../styled.css";

export default function ListTimeRoom({ roomSelected, user, setStep }) {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchList() {
      const res = await getScheduleTimes(user.idEvent, roomSelected);
      if (res.total > 0) setList(res.data);
      setLoading(false);
    }

    fetchList();
  }, [roomSelected, user]);

  if (loading) return <a className="a-loading" />;

  return (
    <div className="div-items-list">
      {list.length > 0 ? (
        list.map((e, i) => (
          <div key={i} style={{ width: "100%" }}>
            <ItemTimeOption data={e} user={user} setStep={setStep} />
          </div>
        ))
      ) : (
        <a style={{ color: "gray" }}>Não há estoque para essa sala</a>
      )}
    </div>
  );
}
